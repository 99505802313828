exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-sudo-scanner-js": () => import("./../../../src/pages/app/sudo-scanner.js" /* webpackChunkName: "component---src-pages-app-sudo-scanner-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-game-click-and-pop-js": () => import("./../../../src/pages/game/click-and-pop.js" /* webpackChunkName: "component---src-pages-game-click-and-pop-js" */),
  "component---src-pages-game-floaty-fish-js": () => import("./../../../src/pages/game/floaty-fish.js" /* webpackChunkName: "component---src-pages-game-floaty-fish-js" */),
  "component---src-pages-game-hex-game-box-js": () => import("./../../../src/pages/game/hex-game-box.js" /* webpackChunkName: "component---src-pages-game-hex-game-box-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-website-ksiazka-oczami-dziecka-js": () => import("./../../../src/pages/website/ksiazka-oczami-dziecka.js" /* webpackChunkName: "component---src-pages-website-ksiazka-oczami-dziecka-js" */)
}

